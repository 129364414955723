<template>
    <v-row justify="center">
        <div v-if="dialog && setting">
            <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar>
                        <v-btn icon @click="$emit('onCloseDialog')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ !setting._id ? "Create Result Setting" : "Update Result Setting"}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn :disabled="loading"
                                   text
                                   @click="validate">
                                {{ !setting._id ? $t('button.create') : $t('button.update') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <Loading :visible="loading" />
                    <v-card-text>
                        <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="setting.title_en" :rules="rules.title_en" label="Title En"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="setting.title_kh" :rules="rules.title_en" label="Title Kh"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="setting.title_vn" :rules="rules.title_en" label="Title Vn"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="setting.title_thai" :rules="rules.title_en" label="Title Thai"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-row>
</template>

<script>
    export default {
        props: {
            selection: {
                type: Array,
                event: "changeSelect",
                default: () => {
                    return [];
                },
            },
            dialog: {
                type: Boolean,
                default: false
            },
            darkmode: {
                type: Boolean
            },
            loading: {
                type: Boolean
            },
            rules: {
                type: Object,
                default: function () {
                    return {
                        title_en: [
                            v => !!v || 'Input is required',
                        ],
                    }
                }
            },
            isValidate: {
                type: Boolean,
                default: true
            },
            setting: {
                type: Object,
                default: function () {
                    return {
                        title_vn: "",
                        title_en: "",
                        title_thai: "",
                        title_kh: ""
                    }
                }
            }
        },
        data: () => ({
            selected_data:[],
        }),
        computed: {
            valid: {
                // getter
                get: function () {
                    return this.isValidate
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:isValidate', newValue)
                }
            },
            selected: {
                get() {
                    return this.selection;
                },
                set(value) {
                    let data = value.map((res) => {
                    return {
                        id: res.id,
                    };
                    });
                    this.selected_data = data;
                    // console.log("data",data);
                    this.$emit("changeSelect", data);
                },
            },
        },
        methods: {
            // select(selected) {
            //     console.log("selected",selected);
            // },
            validate() {
                if (this.$refs.form.validate()) {
                    this.$emit('submit',this.selected_data)
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
